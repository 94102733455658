import * as React from "react"
import type { HeadFC } from "gatsby"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import { SectionWrapper, Node } from "../../components/Sections"
import { HeadingTitle } from "../../components/styling/Heading"
import BorderWrapper, {
  MobileHideWrapper,
} from "../../components/styling/BorderWrapper"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from "react-medium-image-zoom"
import Video from "../../components/styling/Video"

const title = "The Openest of Worlds"

const headings = [
  { title: "Wireframes", id: "Wireframes" },
  { title: "Final Product", id: "Final-Product" },
]

const summmary: Summary = {
  headings: headings,
  details: [
    "Solo Project",
    "2019 (Steam + Itch.io Release)",
    "2020 (DLC Release)",
    <a href="https://store.steampowered.com/app/1144110/Open_World_Game_the_Open_World_Game/">
      OWGTOWG Steam Page
    </a>,
  ],
  role: [
    "UI / UX Designer",
    "UI Programmer",
    "Game Designer",
    "Game Programmer",
  ],
  tools: ["Photoshop", "Unity", "C#"],
  extraContent: (
    <Node.multiCol
      col1={
        <BorderWrapper style={{ width: "100%" }}>
          <Video
            videoSrc="https://www.youtube.com/embed/N3wcgS6hs7Q"
            title="YouTube video player"
          />
        </BorderWrapper>
      }
      col2={
        <MobileHideWrapper>
          <Zoom>
            <StaticImage
              src="../../images/owgtowg/OWGTOWG_Gameplay3.jpg"
              alt="A screenshot of the top down gameplay of OWGTOWG. It features the player arrow icon with a health bar adjacent to a enemy icon with two horns that the player is combatting. A row of directional arrows is above the player that they are expected to press in order with each correct input damaging the enemy."
              loading="eager"
            />
          </Zoom>
        </MobileHideWrapper>
      }
    />
  ),
}

const OWGTOWGPage = () => {
  return (
    <>
      <Layout>
        <SectionWrapper
          as="article"
          heading={
            <HeadingTitle
              name={title}
              subtitle="/overviews/Open-World-Game-the-Open-World-Game"
              summary={summmary}
            />
          }
        >
          <Node.p>
            Open World Game: the Open World Game (OWGTOWG) is a light-hearted
            satire that captures the <b>purest</b> open world game experience.
            Initially, OWGTOWG was just a sandbox I made to learn audio
            implementation, however, in the process of making it, I got hooked
            on the process of making games, greatly expand its scope, and
            polished it into what you see today.
          </Node.p>
          <Node.p>
            I launched OWGTOWG on Steam and Itch.io in 2019 and its reception
            exceeded expectations; as of writing this, it has over 100,000
            downloads and 2,000 reviews — 92% of them positive!
          </Node.p>
          {/* <BorderWrapper style={{ marginTop: "10px" }}>
            <Zoom>
              <StaticImage
                src="../../images/owgtowg/OWGTOWG_Icons.png"
                alt="A list of many of the icons in OWGTOWG. It includes an arrow, a lighthouse beacon, a heart, a feather, a question mark, a fish, a castle, the face of horned enemy without any distiginuishable features, a lizard, a treasure chest, an eye, a sword, an indistiguishable humanoid enemy, a mask, crossed swords, a diamond, an exclamation mark, and a slime."
                layout="fullWidth"
              />
            </Zoom>
          </BorderWrapper> */}
          <Node.multiCol
            style={{ marginTop: "20px" }}
            col1={
              <BorderWrapper>
                <Zoom>
                  <StaticImage
                    src="../../images/owgtowg/OWGTOWG_Gameplay1.jpg"
                    alt="A screenshot of OWGTOWG gameplay where the the player has just leveled up on a green field. The UI in the top left display that the player is level 2 with 149/200 exp. The UI in the top right displays that they have 0% completion and 1 SP. The UI on the right side shows a list of varying (but ultimately irrelevant) experience gained."
                  />
                </Zoom>
              </BorderWrapper>
            }
            col2={
              <BorderWrapper>
                <Zoom>
                  <StaticImage
                    src="../../images/owgtowg/OWGTOWG_Gameplay2.jpg"
                    alt="A screenshot of the player attempting to fish in a map with bright blue boundaries and black background. The UI of collecting the fish shows two meters of a timer running out and the amount the player has to continue align with the fish icon to catch it."
                  />
                </Zoom>
              </BorderWrapper>
            }
          />
          <BorderWrapper>
            <Zoom>
              <StaticImage
                src="../../images/owgtowg/OWGTOWG_Menu2.jpg"
                alt="A screenshot of the OWGTOWG stats menu of a player early on in their playthrough. It shows the list of the 12 main collectables and how many the player has collected as well as section that has yet to be unlocked, and another that lets the player select a different skin for their icon."
              />
            </Zoom>
          </BorderWrapper>
          <Node.multiCol
            style={{ marginTop: "20px" }}
            col1={
              <BorderWrapper>
                <Zoom>
                  <StaticImage
                    src="../../images/owgtowg/OWGTOWG_Menu1.jpg"
                    alt="A screenshot of the three skill trees of OWGTOWG: Generalist, Speed-Runner, and Completionist, each full of indistinguishable and abstract symbols to represent the various skills. The currently selected skill is Aim with the description: you really don't care about unlocking this."
                  />
                </Zoom>
              </BorderWrapper>
            }
            col2={
              <BorderWrapper>
                <Zoom>
                  <StaticImage
                    src="../../images/owgtowg/OWGTOWG_Menu3.jpg"
                    alt="A screenshot of the Journal menu of OWGTOWG that is currently showing a list of locked and unlocked entries in the side quest category. The viewable entry titles are: A Quick Grind, A Quick Trade, Acrophobia, Ancient Manuscripts, Assassins I, Assassins II, Assassins III, and Assassin Training. 
                    
                    The entry A Quick Trade currently open is a pun-y reference to original Pokemon games and reads: You found someone who wouldn't stop asking if you had a Spear Row. When you told them you didn't understand what you meant, they only repeated the question. Maybe it's a bit far fetched, but perhaps you'll run into whatever whatever a Spear Row is later and can help this person out."
                  />
                </Zoom>
              </BorderWrapper>
            }
          />
        </SectionWrapper>
      </Layout>
    </>
  )
}

export default OWGTOWGPage

export const Head: HeadFC = () => <Seo title={title} />
