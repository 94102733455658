import * as React from "react"
import styled from "@emotion/styled"
import { gs } from "../../components/styling/GlobalStyles"

interface Props {
  videoSrc: string
  title: string
}

const DivVideoContainer = styled.div`
  //@media all and (min-width: ${gs.size.screen.md}) {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  //}
`

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  //@media all and (min-width: ${gs.size.screen.md}) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  //margin-top: -32px;
  //}
`

const Video = ({ videoSrc, title }: Props) => {
  return (
    <DivVideoContainer>
      <Iframe src={videoSrc} title={title} allowFullScreen />
    </DivVideoContainer>
  )
}

export default Video
